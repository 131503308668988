<template>

  <modal class="keyboard-shortcuts-modal" :backdrop_click="false" @close="closeModal">
    <template v-slot:body>
      <h3>Keyboard Shortcuts</h3>
      <p>Discover shortcuts to enhance Filmstro experience</p>
      <ShortcutsKeyboard />
    </template>
  </modal>


</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';
import ShortcutsKeyboard from '@/components/ShortcutsKeyboard.vue';
const store = useStore();
const route = useRoute();



let closeModal = () => {
  store.commit('setShowShortcutsKeyboardModal', false);
}


</script>

<style lang="scss">
.keyboard-shortcuts-modal {
  // opacity: .8;
  h3{
    font-size: 2rem;
margin-bottom: 5px;
  }
  p{
    margin-bottom: 20px;
  }

.modal-backdrop{
  background-color: rgba(0, 0, 0, 0.8)!important;
}
  .modal-dialog {
    background: linear-gradient(180deg, #292938, #15151C);
    border-radius: 15px;
    max-width: calc(100% - 30px)!important;

    .modal-content {
      border-radius: inherit;
    }
  }


  .modal-body {
    width: calc(100% - 30px);
    max-width: 1300px;
  }

  .modal-actions {
    justify-content: center;
    gap: 20px;
    display: flex;
    margin-top: 30px;

    button {
      font-size: .8rem;
      margin: 0;
      min-width: 80px;
    }
  }
  .ok-button{
    background: rgb(65, 184, 130);
  }
  .cancel-button{
    background: rgb(255, 118, 116);
  }

  img.logo {
    margin-bottom: 10px;
    width: 60px;
    border-radius: 4px;
  }

  .btn-close {
    color: #D8D8D8 !important;
    font-size: 1.5rem !important;
  }
}
</style>
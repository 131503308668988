export default{
    creatted(el, binding) {
        // console.log('Created UNFOCUS....',el)  ;
    },
    mounted(el, binding) {
        // console.log('Created UNFOCUS....',el)  ;
        el.addEventListener('mouseleave', () => {
            document.activeElement.blur();
        })
        el.addEventListener('click', () => {
            document.activeElement.blur();
        })
    }
}

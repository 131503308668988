<template>

    <modal class="login-required-modal"  @close="closeModal" :show_close_button="true">
      <template v-slot:body>
        <div class="video-modal-box">
          <div class="welcome-video">
              <div class="tutorial-content" :style="`background-image:url(${bg_image})`" >
                <img loading="lazy"  v-if="show_logo" class="logo" src="@/assets/filmstro-logo.png" alt="Filmstro Logo">
                <h3>{{title}}</h3>
                <div class="modal-emoji">{{emoji}}</div>
              </div>
          </div>

          <div class="welcome-description">
            <!-- <p>Upgrade from only $14.99 to save unlimited custom sound track sessions and create favourites</p> -->
          </div>

          <div class="flex gap-1 modal-actions">

            <h6>
              <button @click="goToLogin" class="btn comman-btn modal-btn">{{ $t('login_button_text') }}</button>
              <a @click="goToRegister" class="plan-description"> Register a new account </a>
            </h6>

            <!-- <h6>
              <button @click="goToPricing" class="btn comman-btn modal-btn">VIEW ALL PLANS</button>
            </h6> -->
          </div>
        </div>
      </template>
    </modal>


</template>
<script>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import { website_url } from '@/includes/helpers';
import { useRoute } from 'vue-router';

export default {
    props:{
      show_logo:{
        default:true
      },
      title:{
        default:"You need to be logged in to access this feature"
      },
      emoji:{
        default:""
      }
    },
    setup(){


        let bg_image = require( '@/assets/images/tutorial-bg.png' );
        const store = useStore();
        const route = useRoute();



        let closeModal = ()=>{
          store.commit('setShowLoginRequiredModal',false);
        }



        let goToLogin = ()=>{
          closeModal();
          // store.commit('setShowLoader',true);
          // store.commit('setLoaderMessage','Redirecting to login page')
          store.commit('setLoginModalVisible',true);
        }
        const goToRegister = ()=>{
          closeModal();
          store.commit('setLoginModalVisible',true);
          setTimeout(() => {
            let register_modal_toggle= document.querySelector('.register-modal-toggle');
            if(register_modal_toggle){
              register_modal_toggle.click();
            }
          }, 200);
        }

        return {
            bg_image,
            goToLogin,
            closeModal,
            goToRegister
        }
    }
}
</script>

<style lang="scss">
.login-required-modal {

  .modal-dialog {
    background: linear-gradient(180deg, #292938, #15151C);
    border-radius: 15px;
    max-width: 100%!important;
    .modal-content{
      border-radius: inherit;
    }
  }

  .welcome-video {
      height: 220px;
  }

  .welcome-video iframe {
      width: 100%;
      height: 100%;
  }
  .video-modal-box p{
    font-size: 0.8rem;
    color: #D8D8D8;
  }

  .modal-body{
    width: 500px;
  }
  .welcome-description{
    margin-bottom: 20px;
    p{
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }
  .modal-actions{
    justify-content: center;
    gap: 20px;
    display: flex;
    button{
      font-size: .8rem;
    }
  }

  .tutorial-content {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.8rem;
    line-height: 2rem;
    border-radius: 4px;
    h3 {
      color: white!important;
      font-weight: 800;
    }
    .modal-emoji{
      margin-top: 5px;
    }

  }
  img.logo {
    margin-bottom: 10px;
    width: 60px;
    border-radius: 4px;
  }
  .plan-description {
    display: block;
    margin-top: 10px;
    font-size: .65rem;
    color: #D8D8D8;
    text-decoration: underline;
    cursor: pointer;
  }
  .btn-close{
    color: #D8D8D8!important;
    font-size: 1.5rem!important;
  }
}
</style>